(function ($) {
  $('*[data-bg]').each(function () {
    var bg = $(this).data('bg');
    if (bg.substr(0, 1) === '#') {
      $(this).css({
        'background-color': bg
      });
    } else {
      $(this).css({
        'background-image': 'url(' + bg + ')'
      });
    }
  });
})(jQuery);
