(function ($) {
  $('.clients').each(function () {
    var carousel = $(this).find('.swiper-container');

    var mySwiper = new Swiper(carousel[0], {
      watchOverflow: true,
      spaceBetween: 25,
      slidesPerView: 1,
      slidesPerGroup: 1,
      breakpoints: {
        560: {
          spaceBetween: 50,
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        800: {
          spaceBetween: 75,
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1000: {
          spaceBetween: 100,
          slidesPerView: 4,
          slidesPerGroup: 4,
        }
      },
      pagination: {
        el: carousel.find('.swiper-pagination')[0],
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });
  });
})(jQuery);
